<template>
  <mobile-screen :header="true" screen-class="icon-app1 clean-screen gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="clean-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("clean", "resources", "clean") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <section class="actions-top-wrapper">
      <section class="actions-top">
        <section class="action-location-info">
          <icon icon="#cx-foo1-clean-28x28" />
          <ul class="location-info">
            <li class="uppercase">
              {{ resourceName }}
            </li>
            <li>
              {{ levelName }}
            </li>
          </ul>
        </section>
        <section class="actions-check-in-out">
          <button
            class="body-btn"
            :disabled="disabled"
            @click="cleanResource()"
          >
            {{ displayLabelName("clean", "resources", "finish-clean") }}
          </button>
        </section>
      </section>
    </section>
    <template v-slot:footer>
      <section class="clean-footer-menu icon-foo1"></section>
    </template>
    <info-modal v-if="show" :show="show" icon="checkmark" @close="closeModal">
      <h3 class="modal-title">
        {{ displayLabelName("clean", "resources", "resource-cleaned") }}
      </h3>
    </info-modal>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "CleanResourcesClean",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    InfoModal: defineAsyncComponent(() =>
      import("@/components/global/InfoModal")
    )
  },
  data() {
    return {
      show: false,
      disabled: true,
      resourceName: null,
      levelName: null,
      resourceId: null
    };
  },
  created() {
    this.show = false;
    this.$store.commit("clean/setResourceCleaned", false, { root: true });
    this.resourceCleanCheck();
  },
  computed: {
    ...mapState("clean", ["selectedLevel", "cleanQrCode"]),
    backLinkName() {
      return this.$route.name === "r_clean-resources-clean"
        ? "r_clean"
        : "r_clean-resources-map";
    }
  },
  methods: {
    ...mapActions("clean", ["getLevelResources"]),
    closeModal() {
      this.show = false;
      this.$router.push({ name: this.backLinkName });
      this.$store.commit("clean/setResourceCleaned", true, { root: true });
    },
    resourceCleanCheck() {
      if (!this.cleanQrCode) {
        this.$router.push({ name: this.backLinkName });
      }
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(`${apiEndpoints.company.cleanResources}/check`, {
          qr_code: this.cleanQrCode
        })
        .then(response => {
          this.resourceName = response.data.data.resource;
          this.levelName = response.data.data.level;
          this.resourceId = response.data.data.resource_id;
          this.disabled = false;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement(), this.backLinkName);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    cleanResource() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(`${apiEndpoints.company.cleanResources}`, {
          resource_id: this.resourceId
        })
        .then(() => {
          this.show = true;
          this.getLevelResources();
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement(), this.backLinkName);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
</script>
